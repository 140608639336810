import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'

const NotFoundWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  margin: 70px 10% 0 10%;
  min-height: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundWrapper>
      <h1>Страница не найдена</h1>
      <p><a href="/">Вернуться на главную</a></p>
    </NotFoundWrapper>
  </Layout>
)

export default NotFoundPage
